// Modal Global Style.

@import 'src/assets/styles/globalStyles';

.custom-modal {
    z-index: 1000;
    background: $white;
    border-radius: 16px;

    .close-button {
        border: none;
        background-color: transparent;
    }
}

.modal-width {
    @media (min-width: $xs) {
        width: 311px;
    }
    @media (min-width: $md) {
        width: 400px;
    }
}

// Modal Global Style.

.custom-modal {
    z-index: 1000;
    border-radius: 16px;

    .close-button {
        border: none;
        background-color: transparent;
    }
}

.close-button {
    border: none;
    background-color: transparent;
    padding: 0;
}

.modal-fragment {
    height: auto;
    background: $white;
    border-radius: 8px;
    z-index: 9999;
    position: relative;
    max-height: 80vh;
    overflow: auto;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #e7effb;
    padding: 16px;
    @media (min-width: 768px) {
        padding: 16px 32px 32px;
    }
}

.modal-header-no-padding {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 0;
}

.custom-modal-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #324557;
}

.modal-body {
    padding: 16px;
    @media (min-width: 768px) {
        padding: 16px 32px 32px;
    }
}


//TIMESHEET-RIGHT-MODAL

.timesheet-right-modal {
    margin-left: 16px;
    margin-top: 25px;
    min-width: 358px;
    max-width: 358px;
    min-height: 80vh;
    background: $white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    .timesheet-right-modal-header {
        padding: 16px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #E7EFFB;

        .timesheet-right-modal-details {
            color: $tableHeadText;
            font-family: Open Sans, sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
        }

        .close-edit-btns {
            display: flex;
            align-items: center;

            .dit-btn {
                width: 24px;
                height: 24px;
                cursor: pointer;

                &:hover {
                    opacity: .8;
                }

            }

            .close-button {
                cursor: pointer;
                margin-left: 16px;

                &:hover {
                    opacity: .8;
                }
            }
        }
    }

    .timesheet-right-modal-main-content {
        .border-bottom {
            border-bottom: 1px solid #E7EFFB;

            .timesheet-right-modal-main-content-items {
                padding: 16px 16px 24px;

                .timesheet-right-modal-items-name {
                    color: $gunmetal500;
                    font-family: Open Sans, sans-serif;
                    line-height: 20px;
                    margin-bottom: 12px;
                    width: 143px;

                    &:last-child {
                        margin-bottom: 16px;
                    }
                }


                .timesheet-right-modal-items-param {
                    color: #506c84;
                    text-align: right;
                    font-family: Open Sans, sans-serif;
                    font-weight: 600;
                    line-height: 20px;
                    margin-bottom: 12px;
                }
            }
        }

        .timesheet-right-modal-notes {
            padding: 21px 16px;

            .timesheet-right-modal-notes-block {
                display: flex;
                align-items: center;

                .timesheet-right-modal-notes-image {
                    width: 24px;
                    height: 24px;
                    margin-right: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .timesheet-right-modal-note {
                    color: $gunmetal2;
                    font-family: Open Sans, sans-serif;
                    font-size: 16px;
                    line-height: 24px
                }
            }

            .timesheet-right-modal-note-text {
                color: $tableHeadText;
                line-height: 20px;
                margin-top: 8px;
            }
        }

    }

    .timesheet-right-modal-send-btn-block {
        border-top: 1px solid #E7EFFB;
        padding: 16px;

        .timesheet-right-modal-send-btn {
            margin: 0 auto;
            padding: 6px 24px;
            background: $orange;
            transition: all .3s;
            width: 225px;
            height: 36px;


            &:hover {
                transition: all .3s;
                background: $darkGreen;
            }
        }
    }

}
