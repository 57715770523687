@import 'src/assets/styles/globalStyles';

.drivers-container {
  padding: 42px;
  @media (max-width: $lg) {
    padding: 24px 16px;
  }
  @media (max-width: $md) {
    padding: 16px 0px;
  }

  .desktop-view {
    padding-top: 36px;
  }
}

.driver-info {
  .driver-header {
    background: #ffffff;
    padding: 24px 24px 0px 24px;

    .driver-banner {
      display: flex;
      justify-content: space-between;

      .banner-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
      }

      .header-btn {
        .add-button-style {
          .add-pluss {
            margin-right: 10px;
            font-size: 22px;
            margin-bottom: 5px;
          }
        }

        .popper-btn {
          .popper-wrapper {
            position: relative;
            bottom: 0;
            right: 15px;
          }
        }

        .popper-desktop {
          display: block;
          @media (max-width: $lg) {
            display: none;
          }
        }

        .popper-mobile {
          display: none;
          @media (max-width: $lg) {
            display: block;
          }
        }
      }
    }

    .driver-tab {
      padding-top: 24px;

      .tab-btns {
        width: 100%;

        & .MuiButtonBase-root {
          color: $lightGunmetal;
          font-size: 16px;
          font-weight: 600;
        }

        & .Mui-selected {
          color: $main;
          font-weight: 700;
          font-size: 16px;
        }

        & .MuiTabs-indicator {
          background-color: $main;
        }
      }
    }
  }

  .driver-main {
    .tab-btns {
      width: 100%;

      & .MuiButtonBase-root {
        color: $lightGunmetal;
      }

      & .Mui-selected {
        color: $main;
        font-weight: 700;
      }

      & .MuiTabs-indicator {
        background-color: $main;
      }
    }

    .details-container {
      padding-bottom: 24px;

      .details-form {
        .edit-load {
          color: $main;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          margin-left: 10px;
        }

        .save-load {
          color: $main;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }

        .cance-load {
          color: $tableHeadText;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }

        .btn-space {
          margin-left: 10px;
          margin-right: 10px;
        }

        .load-form {
          background: #ffffff;
          border-radius: 8px;
          padding: 16px;

          .load-title {
            margin-top: 16px;
            margin-bottom: 24px;

            .load-basic {
              font-weight: 700;
              font-size: 18px;
              line-height: 28px;
              color: $gunmetal800;
            }
          }

          .edit-group {
            select {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              background: transparent url('../../assets/images/svg/arrow-down-select.svg') no-repeat 98% center;
            }

            select::-ms-expand {
              display: none;
            }

            .form-control {
              display: block;
              width: 100%;
              padding: 12px 16px 12px 16px;
              line-height: 1.5;
              color: $gunmetal500;
              // background-color: $greyWhite;
              background-clip: padding-box;
              border: 1px solid $gunmetal500;
              border-radius: 4px;
              transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

              :focus {
                border-color: $gunmetal500;
              }
            }
          }
        }

        .histery-box {
          .history-titile {
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: $gunmetal800;
          }

          .close-btn {
            background: transparent;
            border: none;
          }

          .subtitle-box {
            margin-top: 24px;

            .history-subtitle {
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: $orange;
            }
          }

          .card-box {
            margin-top: 24px;
            display: flex;
            flex-direction: column;

            .list-history {
              display: flex;
              height: 44px;
              width: 100%;
              margin-top: 4px;

              .history-time {
                padding: 12px 16px;
                width: 30%;
                background: $lightMain;
                border-radius: 4px 0px 0px 4px;
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: start;
              }

              .history-price {
                padding: 12px 16px;
                width: 70%;
                background: $greyWhite;
                border-radius: 0px 4px 4px 0px;
                display: flex;
                justify-content: start;
                align-items: center;
              }
            }
          }
        }

        .basic-banner {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    .mobile-loads {
      display: none;
    }
  }

  & .MuiButtonBase-root {
    text-transform: capitalize;
  }
}

.driver-history {
  .history-titile {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: $gunmetal800;
  }

  .close-btn {
    background: $greyWhite;
    border-radius: 32px;
  }

  .history-box {
    margin-top: 10px;

    .btn-history {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $main;
      text-decoration-line: underline;
      background: transparent;
      border: none;
    }
  }
}

.rate-container {
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;

  .driver-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: $gunmetal800;
  }

  .basic-title {
    .add-rate {
      background: transparent;
      padding: 6px 24px;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;

      .rate-title {
        margin-left: 5px;
        color: $main;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .rate-body {
    margin-top: 30px;
    margin-bottom: 15px;

    .rate-box {
      background: $greyWhite;
      border-radius: 4px 0px 0px 4px;
      padding: 0 16px;
      height: 44px;
      display: flex;
      align-items: center;

      .rate-icon {
        margin-right: 12px;
      }

      .rate-text {
        font-size: 14px;
        line-height: 20px;
        color: $gunmetal;

        .subtitle {
          font-weight: 400;
          margin-left: 5px;
        }
      }
    }
  }

  .post-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 16px;

    .post-btn {
      background: transparent;
      border: none;

      .post-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
        color: $gunmetal500;
      }
    }
  }

  .fuel-body {
    padding-top: 20px;

    .card-header {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      padding: 15px 60px 15px 16px;
      background: $lightMain;
      border-radius: 4px;

      .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $gunmetal;
      }
    }

    .card-body {
      margin-top: 8px;

      .fuel-box {
        margin-top: 4px;
        padding: 14px 40px 14px 14px;
        background: $greyWhite;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .fuel-phone {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $gunmetal;
        }

        .pay-box {
          margin-right: 10px;
        }
      }

      .edit-btn {
        background: transparent;
        border: none;
        margin-right: 5px;
      }

      .delete-btn {
        background: transparent;
        border: none;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
}

@media only screen and (max-width: 767px) {
  .driver-info {
    .driver-main {
      .desktop-loads {
        display: none;
      }

      .mobile-loads {
        display: block;
      }

      .details-container {
        .details-form {
          .edit-load {
            display: none;
          }
        }
      }
    }
  }
  .driver-banner {
    .header-btn {
      .add-button-style {
        width: 48px;

        .add-pluss {
          font-size: 18px;
          margin-right: 0;
          margin-bottom: 0;
        }

        .add-text {
          display: none;
        }
      }
    }

    .banner-box {
      display: flex;
      flex-direction: column;
      width: 100%;

      .header-btn {
        margin-top: 24px;

        .input-wrapper {
          .select-dropdown {
            width: 100%;
          }
        }
      }
    }
  }
  .driver-tab {
    .details-container {
      padding: 16px;
    }

    & .MuiButtonBase-root {
      align-items: baseline;
    }

    .loads-container {
      .totals-header {
        .totals-trafic {
          grid-template-columns: max-content;

          .loads-box {
            display: none;
          }
        }
      }

      .loads-table {
        margin-top: 16px;

        .details-box {
          padding: 16px;

          .table-details {
            padding: 12px;
            background-color: $greyWhite;
            display: grid;
            grid-template-columns: auto auto auto auto;
            grid-gap: 20px;

            .title {
              color: $gunmetal;
              font-size: 14px;
            }

            .number {
              color: $lightGunmetal;
              font-size: 14px;
            }
          }
        }

        .accordion-header {
          width: 100%;
        }

        .profit-header {
          .profit-menu {
            .popper-wrapper {
              position: relative;
              transform: rotate(90deg);
            }
          }
        }
      }

      .tabls-header {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .type-header {
          font-size: 18px;
          line-height: 28px;
          display: flex;

          .load-id {
            font-weight: 700;
            color: $gunmetal800;
          }

          .load-text {
            margin-left: 5px;
            font-weight: 700;
            color: $gunmetal800;
          }
        }

        .profit-header {
          display: flex;
          align-items: center;

          .profit-number {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            margin-right: 15px;
          }

          .profit-menu {
            display: block;
            margin: 0 15px;

            .menu-btn {
              border: none;
              background-color: transparent;
            }
          }
        }
      }

      .panel-mobile {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 16px;

        .panel-row {
          display: flex;
          align-items: center;

          .profit-number {
            display: flex;
            padding-left: 10px;
          }
        }
      }
    }
  }
  .rate-container {
    .title-basic {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
    }

    .desktop-text {
      display: none;
    }
  }

  .mobile-loads {
    display: block;
  }
}

.rate-mobile-title {
  font-weight: 600;
  color: $gunmetal;
  white-space: nowrap;
}

.past-rate-icon {
  margin-right: 10px;
}

.past-rate-subtitle {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #506C84;
  margin-left: 4px;
}

.timesheet-driver-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 90vh;

  @media only screen and (max-width: $md) {
    padding: 0 16px;
  }


  .timesheet-driver-filters-block {
    display: flex;
    justify-content: space-between;

    .items-are-selected {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 12px 16px;
      background: $lightOrange;

      .items-are-selected-svg-text {
        display: flex;
        align-items: center;

        .items-are-selected-svg {
          cursor: pointer;
        }

        .items-are-selected-text {
          color: $gunmetal800;
          font-family: Open Sans, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          padding: 6px 16px;
          margin-left: 16px;
        }
      }


      .items-are-selected-button {
        border: none;
        background: transparent;
        color: #ff9a00;
        width: 178px;
        height: 36px;
        transition: all .3s;

        &:hover {
          color: $darkGreen;
          transition: all .3s;

        }
      }
    }

    .select-all-cancel-buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 24px 0;
      margin: 0 auto;

      .cancel-button, .select-all-button {
        padding: 6px 16px;
        border: none;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        background: transparent;
      }

      .cancel-button {
        color: $tableHeadText;

        &:hover {
          color: $darkGunmetal;
        }
      }

      .select-all-button {
        color: $main;

        &:hover {
          color: $darkGreen;
        }
      }
    }

    .timesheet-driver-buttons-calendar-block {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: $md) {
        margin: 0 auto;
        flex-direction: column-reverse;
        padding: 32px 0;
      }

      .timesheet-driver-calendar-button {
        display: flex;
        gap: 16px;

        @media only screen and (max-width: $md) {
          width: 100%;
          margin-bottom: 16px;
        }


        .timesheet-driver-calendar {
          max-width: 163px;
          width: 100%;

          @media only screen and (max-width: $md) {
            max-width: 100%;
          }
        }

      }

      .MuiPaper-root {
        @media only screen and (max-width: 767px) {
          width: 100% !important;
          .MuiTabs-root {
            width: 100%;

            .MuiTabs-fixed {
              width: 100%;

              .MuiTabs-flexContainer {
                width: 100%;

                .MuiButtonBase-root {
                  width: calc(50% - 2px) !important;
                }
              }
            }
          }
        }
      }

    }
  }

  .main-content-desktop {
    display: flex;
  }

  .main-content-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .main-content-tablet {
    margin-top: 24px;

    .MuiPaper-root .MuiCollapse-root {
      margin: 0;

    }

    .timesheet-full-table {
      padding: 0 10px 24px;
      background: $white;

      .table-wrapper {
        margin: 0;


        .MuiTableContainer-root {
          height: fit-content;
          margin: 0;


          .table-head {
            background: white;

            th {
              background: $white;
            }
          }

          tbody {
            .table-row {
              &:hover {
                td {
                  border-top: 1px solid $main;
                  border-bottom: 1px solid $main;

                  &:first-child {
                    border-left: 1px solid $main;
                  }

                  &:last-child {
                    border-right: 1px solid $main;
                  }
                }
              }

              &.tbody-table-row-active {
                td {
                  border-top: 1px solid $main;
                  border-bottom: 1px solid $main;

                  &:first-child {
                    border-left: 1px solid $main;
                  }

                  &:last-child {
                    border-right: 1px solid $main;
                  }
                }
              }

              td {
                background: $greyWhite;
                border: 1px solid transparent;
              }
            }
          }
        }

        .data-container {
          .data-img {
            margin: 0;
          }
        }
      }
    }
  }
}


.MuiButtonBase-root.MuiCheckbox-root {
  padding: 0;

  .MuiSvgIcon-root {
    fill: $main;
  }
}


.main-tooltip-wrapper {

  .text {
    display: inline-block;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #506C84;
    white-space: break-spaces;
    overflow-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    .notes {
      color: #506c84;
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin-right: 5px;
    }
  }
}

//ADD-CANCEL-TIMESHEET-RIGHT-MODAL

.add-timesheet-driver-modal-wrapper {
  max-width: 514px;
  width: 514px;

  @media (max-width: 768px) {
    max-width: 343px;
    width: 100%;
  }

  .modal-header {
    width: 100%;
    padding: 16px 32px;

    .modal-header-text {
      color: $gunmetal800;
      font-family: Open Sans, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
    }
  }

  .modal-body {
    padding: 0;
    overflow-y: auto;
    height: calc(80vh - 80px);

    .modal-body-inputs {
      padding: 16px 16px 0;
      @media (min-width: 768px) {
        padding: 16px 32px 0;
      }
    }

    .buttons-wrapper {
      padding: 16px 16px 16px;
      border-top: 1px solid #E7EFFB;

      @media (min-width: 768px) {
        padding: 16px 32px 16px;
      }
    }
  }
}