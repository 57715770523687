// Global Style.

$white: #ffffff;
$greyWhite: #f5f7f9;
$neutralGrey: #bfbfbf;
$main: #0e9594;
$darkText: #2a374e;
$darkGreen: #0f7a79;
$lightMain: #e1f2f3;
$lightGrey: #abbdcf;
$orange: #ff9a00;
$lightOrange: #fff3e0;
$gunmetal: #506c84;
$gunmetal2:#758EA6;
$lightGunmetal: #8ca2b8;
$darkGunmetal: #20313f;
$alphaGunmetal: #3c4151;
$gunmetal500: #5d7a95;
$gunmetal800: #324557;
$darkElectric: #545f7e;
$tableHeadText: #41586d;
$red: #d03325;
$lightgray: #387dff;
$success: #0bb53a;
$blue: #4489f2;

// Size Option.
$xs: 320px;
$sm: 375px;
$md: 767px;
$lg: 1024px;
$dp: 1279px;
$xl: 1440px;

::-webkit-scrollbar {
    display: none;
}

html {
    scroll-behavior: smooth;
    & .MuiContainer-maxWidthLg {
        @media (min-width: 1200px) {
            max-width: 1440px;
        }
    }
}

.MuiOutlinedInput-input:focus {
    outline: none;
}

.brd {
    border: 1px solid $red;
}

.error-messages {
    color: $red;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 5px;
    margin-left: 15px;
    height: 15px;
}

.error-box {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    font-weight: 600;
}

.form-messages {
    display: flex;
    flex-direction: column;
}

// Input Style.

.group-input {
    label {
        display: inline-block;
        margin-bottom: 4px;
        color: $darkGunmetal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-top: 20px;
    }

    .form-control {
        display: block;
        width: 100%;
        padding: 12px 16px 12px 16px;
        line-height: 1.5;
        color: $gunmetal500;
        background-color: $greyWhite;
        background-clip: padding-box;
        border: 1px solid $gunmetal500;
        border-radius: 4px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        :focus,
        :focus-visible,
        :focus-within {
            border-color: $gunmetal500;
        }

        .error-border {
            border: 1px solid $red;
        }
    }

    .text-area {
        display: block;
        width: 100%;
        padding: 12px 16px 12px 16px;
        line-height: 1.5;
        color: $gunmetal500;
        background-color: $greyWhite;
        background-clip: padding-box;
        border: 1px solid $gunmetal500;
        border-radius: 8px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        outline: none;
        :focus {
            border-color: $gunmetal500;
        }
    }
}

.edit-group {
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: transparent url('../../assets/images/svg/arrow-down-select.svg') no-repeat 98% center;
    }
    select::-ms-expand {
        display: none;
    }
    .form-control {
        display: block;
        width: 100%;
        padding: 12px 16px 12px 16px;
        line-height: 1.5;
        color: $gunmetal500;
        background-color: $greyWhite;
        background-clip: padding-box;
        border: 1px solid $gunmetal500;
        border-radius: 4px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        :focus {
            border-color: $gunmetal500;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    //-webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset;
    box-shadow: 0 0 0 30px #ffffff inset;
    color: $darkElectric;
}

input:focus-visible {
    outline: none;
}

select:focus-visible {
    outline: none;
}

input[type='file'] {
    display: none;
}

div.calendar .title,
div.calendar .button {
    color: #352b21 !important;
}

div.calendar .today {
    background: #ffcc00 !important;
}

div.calendar table {
    background-color: #c2b8af !important;
}

// .-webkit-datetime-edit {
//     color: #0bb53a !important;
// }

input[type='time'] {
    color: red; /* change the color here */
}

.mobile-filtering {
    top: 155px;

    & .MuiPaper-root {
        top: 155px !important;
        width: 125px;
    }

    .filtering-subtitle {
        padding: 8px;
        display: flex;
        justify-content: space-between;
        .subtitle {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #506c84;
        }
    }
}

// Font.

@font-face {
    font-family: 'NoirProRegular';
    src: local('NoirProRegular'), url('../fonts/NoirPro-Regular.otf') format('truetype');
    font-weight: normal;
}

.img-view {
    width: -webkit-fill-available;
    height: 100%;
}

// Global styles

.flex {
    display: flex;
    align-items: center;
}

.flex-full-width {
    display: flex;
    align-items: center;
    width: 100%;
}

.flex-gap-16 {
    gap: 16px;
}
.gunmetal {
    color: #506c84;
}
.align-start {
    display: flex;
    justify-content: flex-start;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flex-end {
    width: 100%;
    justify-content: flex-end;
    display: flex;
}

.jusify-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-start {
    width: 100%;
    justify-content: flex-start;
    display: flex;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.font-bold {
    font-weight: 700;
}

.font-semiBold {
    font-weight: 600;
}

.font-normal {
    font-weight: 400;
}

.text-center {
    text-align: center;
}

.full-width {
    width: 100%;
}

// Global styles End

// Typography Style.

.text-h1 {
    font-style: normal;
    font-size: 48px;
    line-height: 72px;
}

.text-h2 {
    font-style: normal;
    font-size: 40px;
    line-height: 60px;
}

.text-h4 {
    font-style: normal;
    font-size: 24px;
    line-height: 36px;
}

.text-xl {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
}

.text-error {
    color: $red;
}

.text-gunmetal {
    color: $gunmetal;
}
.text-gunmetal-light {
    color: #506c84;
}

.text-success {
    color: $success;
}

.disbled-btn {
    display: flex;
    border: none;
    background: transparent;
    align-items: center;
    .stop-title {
        color: #c4c4c4 !important;
        pointer-events: auto !important;
        cursor: not-allowed !important;
    }
}

.disabled-icon {
    color: #c4c4c4 !important;
    pointer-events: auto !important;
    cursor: not-allowed !important;
}

.disabled-button {
    background: #e9e9e9 !important;
    color: #324557 !important;
    pointer-events: auto !important;
    cursor: not-allowed !important;
    .button-text {
        color: #324557 !important;
    }
}

// Header Component.

.header-section {
    padding: 20px;
    background-repeat: no-repeat;
    background-position: 100%;
    position: fixed;
    width: 100%;
    background: white;
    z-index: 5000;
    @media (max-width: $md) {
        padding: 16px 0;
    }
    .header-box {
        display: flex;
        justify-content: space-between;
        .box-logo {
            padding-top: 10px;
        }
        .dark-link {
            margin-right: 24px;
        }
        .header-links {
            a {
                text-decoration: none;
            }
            @media only screen and (max-width: $lg) {
                display: none;
            }
        }
        .burger-link {
            display: none;
            @media only screen and (max-width: 1023px) {
                display: block;
            }
        }
    }

    .dark-link {
        color: $darkGunmetal;
        text-transform: none;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }
}

// Footer Component.

.footer {
    background-color: $main;
    padding: 20px 0;
    color: white;

    .footer-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media (max-width: 767px) {
            justify-content: center;
            flex-direction: column;
        }
    }

    .footer-title {
        margin: 0 20px;
        @media (max-width: 767px) {
            margin: 5px;
        }
    }

    .footer-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        @media (max-width: $md) {
            width: 100%;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
        }
        .terms-link {
            margin-bottom: 15px;
        }
        .privacy-link {
            margin-left: 15px;
            margin-bottom: 15px;
        }
        .privacy-text {
            color: white;
            text-decoration: underline;
        }
    }

    .footer-info {
        @media (max-width: $sm) {
            font-size: 14px;
            width: 100%;
        }
        @media (max-width: $md) {
            margin-top: 15px;
        }
    }

    .footer-subtitle {
        margin: 20px 0;
    }

    .footer-img {
        @media (max-width: 767px) {
            margin-bottom: 10px;
        }
    }
}

.box-footer {
    display: flex;
    align-items: center;
    padding: 16px;
    justify-content: flex-end;
    .text-error {
        font-size: 16px;
    }
    @media (max-width: $md) {
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }
}

// Custom Materila Style.

.MuiListItem-alignItemsFlexStart {
    padding-left: 8px;
    padding-right: 8px;
}

.css-hyxlzm {
    svg {
        color: $main;
    }
}

// Side Menu Component

.side-container {
    padding: 16px;
    @media only screen and (min-width: 768px) {
        padding: 40px;
    }

    .drawer-link-styles {
        text-decoration: none;
    }
    .logo-img {
        padding: 0 12px;
    }
    .side-link {
        padding: 12px;

        .dark-link {
            font-size: 16px;
            line-height: 24px;
            color: $darkGunmetal;
        }

        .green-link {
            font-size: 16px;
            line-height: 24px;
            color: $main;
        }

        .css-10hburv-MuiTypography-root {
            font-weight: 600;
            font-family: 'NoirProRegular';
        }
    }
}

.landing-page {
    .css-ke5b6m-MuiButtonBase-root-MuiButton-root:hover {
        background-color: $darkGreen;
    }
}

.active-btn {
    background: $white;
    border-radius: 4px;
    padding: 12px 87px 12px 86px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #324557;
    border: none;
    outline: none;
    & :hover {
        background: #d9d9d9;
    }
}

.toast-wrapper {
    display: flex;
    align-items: center;

    img {
        margin-right: 16px;
    }

    p {
        font-size: 16px;
        color: $darkGunmetal;
    }
}

.mobile-view {
    .menu-btn {
        border: none;
        background-color: transparent;
    }
    .wrapper-header {
        margin-bottom: 20px;
        .header-btn {
            display: flex;
            flex-direction: row;
        }
        .filter-menu {
            margin-right: 24px;
            .filter-button-style {
                height: 40px;
                border-radius: 4px;
                width: 40px;
                text-align: center;
                border: none;
                background: transparent;
            }
            .filter-text {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: $main;
            }
        }
    }
    .card-box {
        margin: 8px 0;
        cursor: pointer;
    }
    @media (min-width: $xs) {
        display: block;
    }
    @media (min-width: $md) {
        display: none;
    }
}

.desktop-view {
    @media (min-width: $xs) {
        display: none;
    }
    @media (min-width: $md) {
        display: block;
    }
}

.wrapper-box {
    @media (min-width: $xs) {
        display: none;
    }
    @media (min-width: $md) {
        display: block;
    }
}

.custom-error-messages {
    color: $red;
    font-size: 12px;
    font-weight: 400;
    height: 18px;
    margin-top: 2px;
    margin-left: 15px;
}

// Global Page Style.

.body-wrapper {
    padding: 0 24px;
}

.page-container {
    .desktop-view {
        padding-top: 0;
    }
    .mobile-view {
        padding: 16px;
    }
}

.info-container {
    .status-style {
        background: #f5f7f9;
        border-radius: 4px;
        align-items: center;
        padding: 6px 16px;
        display: flex;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #324557;
    }
    .info-header {
        background: $white;
        padding: 32px 32px 0px 32px;
        @media (max-width: $lg) {
            padding: 24px 24px 0px 24px;
        }
        .info-banner {
            display: flex;
            justify-content: space-between;
            @media (max-width: 767px) {
                display: flex;
                flex-direction: column;
            }
        }
        .info-router {
            display: none;
            @media (max-width: $md) {
                display: block;
                margin-top: 16px;
                margin-bottom: 24px;
            }
        }
        .info-tab {
            padding-top: 24px;
            .info-btns {
                width: 100%;
                & .MuiButtonBase-root {
                    color: $lightGunmetal;
                    font-size: 16px;
                    font-weight: 600;
                }
                & .Mui-selected {
                    color: $main;
                    font-weight: 700;
                }
                & .MuiTabs-indicator {
                    background-color: $main;
                }
            }
        }
    }
    .info-main {
        padding: 32px 32px 0 32px;
        @media (max-width: $lg) {
            padding: 16px 16px 0 16px;
        }
        @media (max-width: $md) {
            padding: 0;
        }
    }
    & .MuiButtonBase-root {
        text-transform: capitalize;
    }
}

.upload-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 40px;
    background: #e1f2f3;
    cursor: pointer;
    border: 1px solid #e1f2f3;
    position: relative;
    bottom: 32px;
    left: 22px;
}

.alert-container {
    width: 100%;
    background: #fff3e0;
    padding: 0px;
    position: fixed;
    top: 0;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    height: 56px;
    .alert-box {
        display: flex;
        align-items: center;
        @media (max-width: $md) {
            justify-content: space-between;
        }
        .notificatin-box {
            display: flex;
            align-items: center;
            @media (max-width: $md) {
                display: none;
            }
        }
        .info-box {
            font-size: 16px;
            line-height: 24px;
            margin: 0 12px;
            @media (max-width: $md) {
                margin: 0 8px;
            }
            .text-info {
                color: #41586d;
                font-weight: 400;
            }

            .text-orange {
                color: #ff9a00;
                font-weight: 700;
            }
        }
        .upgrade-box {
            margin: 0 20px;
            @media (max-width: $md) {
                margin: 0;
                width: 50%;
            }
            .upgrate-btn {
                border: none;
                background: #ff9a00;
                border-radius: 4px;
                padding: 6px 24px;
                color: #ffffff;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                @media (max-width: $md) {
                    width: 100%;
                    padding: 6px 13px;
                }
            }
        }
        .days-text {
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}

.alert-ended {
    width: 100%;
    background: #ffe9e9;
    padding: 0px;
    position: fixed;
    top: 0;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    height: 56px;
    .alert-box {
        display: flex;
        align-items: center;
        @media (max-width: $md) {
            justify-content: space-between;
        }
        .notificatin-box {
            display: flex;
            align-items: center;
            @media (max-width: $md) {
                display: none;
            }
        }
        .info-box {
            font-size: 16px;
            line-height: 24px;
            margin: 0 12px;
            @media (max-width: $md) {
                margin: 0 8px;
            }
            .text-info {
                color: #d03325;
                font-weight: 400;
            }
            .text-bold {
                color: #d03325;
                font-weight: 700;
            }
        }
        .upgrade-box {
            margin: 0 20px;
            @media (max-width: $md) {
                margin: 0;
                width: 50%;
            }
            .upgrate-btn {
                border: none;
                background: #d03325;
                border-radius: 4px;
                padding: 6px 24px;
                color: #ffffff;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                @media (max-width: $md) {
                    width: 100%;
                    padding: 6px 16px;
                }
            }
        }
        .days-text {
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}

.privacy-page {
    padding: 160px 20px;

    @media (max-width: $md) {
        padding: 120px 16px;
    }
    .page-info {
        .title-box {
            margin-bottom: 48px;
            .privacy-title {
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                color: #2b273c;
            }
            .space {
                margin: 0 5px;
            }
        }
        .privacy-body {
            background: #f5f7f9;
            border-radius: 12px;
            padding: 40px;
            .privacy-subtitle {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #757280;
                margin-bottom: 24px;
            }
        }
    }
}

.Toastify__progress-bar--animated {
    background: white;
}

::placeholder {
    color: #8ca2b8 !important;
}

.text-style {
    width: 100%;

    .price-row-style {
      color: $gunmetal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
}

.mobile-card-wrapper {
    background: #ffffff;
    border-radius: 4px;
    padding: 16px;
}
.MuiGrid-item {
    padding-top: 2px !important;
}

.HtmlToolTip {
    .HtmlToolTip-text {
        color: red;
    }
}
