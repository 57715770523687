// Button Style.

@import 'src/assets/styles/globalStyles';

.green-btn {
  width: 100%;
  padding: 12px 56px;
  background-color: $main;
  text-transform: none;
  color: $white;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background: $darkGreen;
  }
}

button {
  text-transform: none;
}

.send-button {
  margin-top: 16px;
}

.outlined-btn {
  background: $white;
  border-radius: 4px;
  padding: 12px 87px 12px 86px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #324557;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;

  &:hover {
    background: #d9d9d9;
  }
}

// Buttons Tab

.buttons-tab-wrapper {
  min-height: fit-content !important;

  & .MuiPaper-root {
    background: none;
    box-shadow: none;
  }

  & span {
    background: none;
  }

  & .MuiTabs-flexContainer {
    border-radius: 18px;
    display: flex;
    align-items: center;
  }

  & .MuiTabs-root {
    min-height: 36px !important;
    height: 36px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .MuiTabs-fixed {
    min-height: 36px;
    display: flex;
    align-items: center;
  }

  & .PrivateTabIndicator-colorPrimary-30 {
    display: none;
  }

  & .MuiTab-root {
    min-height: 36px;
  }

  & .MuiTab-textColorPrimary.Mui-selected {
    height: 32px;
    background: $main;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $white;
    text-transform: capitalize;
    margin-left: 2px;

    & :hover {
      background: #0000001a 0% 0% no-repeat padding-box;
      height: 32px;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $white;
      text-transform: capitalize;
    }
  }

  & .MuiTab-textColorPrimary {
    min-width: 103px;
    height: 32px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $gunmetal800;
    text-transform: capitalize;
    margin-left: 2px;

    & :hover {
      background: #e6ecf380 0% 0% no-repeat padding-box;
      min-width: 103px;
      height: 32px;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $gunmetal800;
      text-transform: capitalize;
    }
  }
}

.add-button {
  background: $main;
  padding: 12px;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  border: none;
  outline: none;
  border-radius: 4px;

  .button-text {
    color: $lightMain;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  &:hover {
    background: $darkGreen;
  }

  .button-icon-wrapper {
    display: flex;
    align-items: center;

    .button-icon {
      margin-right: 8px;
    }
  }
}

.create-cancel-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;

  .cancel-button {
    margin-right: 12px;
    width: 100%;
    height: 48px;
    background: $greyWhite;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $gunmetal800;
    text-transform: capitalize;
  }
}

.back-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background: transparent;

  .text-btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $gunmetal;
    margin-left: 10px;
  }
}

.mobile-wrapper-btn {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  @media (min-width: 320px) {
    display: flex;
  }
  @media (min-width: 1023px) {
    display: none;
  }

  .mobile-add-button-style {
    background: $main;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }
}

.add-btn-wrapper {
  display: flex;
  align-items: center;
  padding: 0 24px;
  background: transparent;
  border: none;

  img {
    margin-right: 8px;
  }

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $main;
  }
}

.more-less-button-wrapper {
  width: 100%;

  .more-less-button {
    width: 100%;
    display: flex;
    align-items: center;
    background: $greyWhite;
    padding: 8px 36px;
    box-shadow: none;

    &:hover {
      background: #e6e8ea;
      box-shadow: none;
    }

    .more-less-button-text {
      color: $tableHeadText;
      text-align: center;
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .more-less-button-svg {
      margin-left: 8px;
      transition: .3s;

      &.show {
        transform: rotate(180deg);
        transition: .3s;
      }

      path {
        fill: $tableHeadText;
      }
    }
  }
}

//ButtonWithSvg


.button-with-svg {
  background: $main;
  padding: 6px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  border: none;
  outline: none;
  border-radius: 4px;
  gap: 8px;

  .text-button {
    color: $lightMain;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    white-space: nowrap;

    @media only screen and (max-width: $md){
      display: none;
    }
  }

  &:hover {
    background: $darkGreen;
  }

  .svg-button {
    display: flex;
    align-items: center;
  }
}