.main-tooltip-wrapper {

  .main-tooltip-text {
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #506C84;
    white-space: break-spaces;
    overflow-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }


}

.MuiTooltip-popper {
  z-index: 1000000 !important;

  .MuiTooltip-tooltip {
    background: #324557;
    color: #FFF;
    font-family: Open Sans, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    padding: 10px;
  }
}