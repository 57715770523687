@import "../../../assets/styles/globalStyles";

.payroll-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 90vh;
  width: 100%;
  cursor: default;
  padding: 32px 42px;

  @media (max-width: $md) {
    padding: 32px 16px;
  }

  .payroll-filters-block {
    display: flex;
    justify-content: space-between;

    .items-are-selected {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 12px 16px;
      background: $lightOrange;

      .items-are-selected-svg-text {
        display: flex;
        align-items: center;

        .items-are-selected-svg {
          cursor: pointer;
        }

        .items-are-selected-text {
          color: $gunmetal800;
          font-family: Open Sans, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          padding: 6px 16px;
          margin-left: 16px;
        }
      }


      .items-are-selected-button {
        border: none;
        background: transparent;
        color: #ff9a00;
        transition: all .3s;

        &:hover {
          color: $darkGreen;
          transition: all .3s;
        }
      }
    }

    .select-all-cancel-buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 24px 0;
      margin: 0 auto;

      .cancel-button, .select-all-button {
        padding: 6px 16px;
        border: none;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        background: transparent;
      }

      .cancel-button {
        color: $tableHeadText;

        &:hover {
          color: $darkGunmetal;
        }
      }

      .select-all-button {
        color: $main;

        &:hover {
          color: $darkGreen;
        }
      }
    }

    .payroll-buttons-calendar-input-block {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: $md) {
        margin: 0 auto;
        flex-direction: column-reverse;
        padding: 32px 0;
      }

      .payroll-calendar-block {
        display: flex;
        gap: 16px;

        @media only screen and (max-width: $md) {
          width: 100%;
          margin-bottom: 16px;
        }


        .payroll-driver-calendar {
          max-width: 163px;
          width: 100%;

          @media only screen and (max-width: $md) {
            max-width: 100%;
          }
        }

      }

      .input-wrapper {
        .custom-select {
          margin: 0;
          border: none;
          background: $white;
          height: 40px;
        }

        p {
          display: none;
        }
      }

      .MuiPaper-root {

        @media only screen and (max-width: 767px) {
          width: 100% !important;

          .MuiTabs-root {
            width: 100%;

            .MuiTabs-fixed {
              width: 100%;

              .MuiTabs-flexContainer {
                width: 100%;

                .MuiButtonBase-root {
                  width: calc(50% - 2px) !important;
                }
              }
            }
          }
        }
      }

      .payroll-buttons-calendar-input-block-tablet {
        width: 100%;

        .payroll-tabs-button-tablet {
          display: flex;
          justify-content: space-between;
        }

        .payroll-input-calendar {
          display: flex;
          justify-content: space-between;
          gap: 16px;
          margin: 16px 0 24px;

          .payroll-filter-search {
            width: 63%;
          }

          .payroll-buttons-calendar-input-block-tablet_calendar {
            max-width: 37%;
          }
        }
      }

      .payroll-buttons-calendar-input-block-mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .payroll-calendar-button-mobile {
          display: flex;
          gap: 16px;
        }
      }

    }
  }

  .main-content-desktop {
    display: flex;
  }

  .payroll-main-content-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

  }

  .payroll-main-content-tablet {
    display: flex;
    margin-top: 24px;

    .payroll-right-modal {
      margin-top: 0;
      min-height: 70vh;
    }

    .MuiPaper-root .MuiCollapse-root {
      margin: 0;

    }

    .timesheet-full-table {
      padding: 0 10px 24px;
      background: $white;

      .table-wrapper {
        margin: 0;


        .MuiTableContainer-root {
          height: fit-content;
          margin: 0;


          .table-head {
            background: white;

            th {
              background: $white;
            }
          }

          tbody {
            .table-row {
              &:hover {
                td {
                  border-top: 1px solid $main;
                  border-bottom: 1px solid $main;

                  &:first-child {
                    border-left: 1px solid $main;
                  }

                  &:last-child {
                    border-right: 1px solid $main;
                  }
                }
              }

              &.tbody-table-row-active {
                td {
                  border-top: 1px solid $main;
                  border-bottom: 1px solid $main;

                  &:first-child {
                    border-left: 1px solid $main;
                  }

                  &:last-child {
                    border-right: 1px solid $main;
                  }
                }
              }

              td {
                background: $greyWhite;
                border: 1px solid transparent;
              }
            }
          }
        }

        .data-container {
          .data-img {
            margin: 0;
          }
        }
      }
    }
  }
}

.MuiPaper-root {
  border-radius: 4px 4px 0 0;

  .payroll-drawer-modal {
    margin: 0;
    width: 100%;
    max-width: 100%;
    height: fit-content;

    .payroll-drawer-modal-header {
      padding: 16px 24px;

      .timesheet-right-modal-details {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        text-transform: capitalize;
      }
    }

    .payroll-drawer-items-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 90%;
      padding-left: 24px !important;

      .timesheet-right-modal-items-name, .timesheet-right-modal-items-param {
        color: #758EA6;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .payroll-drawer-notes-wrapper {
      display: flex;
      align-items: center;

      .timesheet-right-modal-note-text {
        margin-top: 0;
        margin-left: 10px;
      }
    }
  }
}

//ADD-CANCEL-PAYROLL-MODAL

.add-payroll-driver-modal-wrapper {
  max-width: 514px;
  width: 514px;

  @media (max-width: $md) {
    max-width: 343px;
    width: 100%;
  }

  &.loading-width {
    @media (max-width: $md) {
      width: 343px;
    }
  }

  .modal-header {
    width: 100%;
    padding: 16px 32px;

    .modal-header-text {
      color: $gunmetal800;
      font-family: Open Sans, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
    }
  }

  .modal-body {
    padding: 0;
    overflow-y: auto;
    height: calc(80vh - 80px);


    .modal-body-inputs {
      padding: 16px 16px 0;

      @media (min-width: $md) {
        padding: 16px 32px 0;
      }

      .dates-block {
        display: flex;
        gap: 14px;

        @media (max-width: $md) {
          display: block;
        }
      }
    }


    .buttons-wrapper {
      padding: 16px 16px 16px;
      border-top: 1px solid #E7EFFB;

      @media (min-width: 768px) {
        padding: 16px 32px 16px;
      }
    }
  }
}

