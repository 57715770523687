@import 'src/assets/styles/globalStyles';

.add-note-wrapper {
  .truck-tab {
    background-color: #f5f7f9;
  }

  .documents-wrapper {
    .title {
      display: none;
    }

    .sections-wrapper {
      padding: 16px;
      flex-direction: column-reverse !important;

      .upload-docks {
        border: 4px dashed $main;
        margin-left: 0;

        .drag-drop-text {
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;

          > span {
            color: $main;
          }
        }
      }
    }
  }

  &.maintenance-modal-wrapper {
    overflow: hidden;
    max-height: 80vh;

    .maintenance-modal-body-wrapper {
      overflow-y: auto;
      max-height: calc(80vh - 80px);

      .tab-padding {
        padding: 0 16px;
        margin-top: 24px;

        @media (min-width: 768px) {
          padding:0 32px;
        }
      }

      .add-maintenance-inputs {
        padding: 0 16px;

        @media (min-width: 768px) {
          padding:0 32px;
        }
      }
      .maintenance-modal-buttons-wrapper {
        padding: 16px;

        @media (min-width: 768px) {
          padding: 16px 32px;
        }
      }
    }
  }

  .maintenance-modal-cancel-btn {
    width: fit-content;
    background: $greyWhite;
    color: $tableHeadText;
    padding: 12px 83px;
    margin-right: 16px;

    @media only screen and (max-width: $md) {
      padding: 12px 48px;
    }
  }

  .maintenance-modal-textarea-wrapper {
    padding-bottom: 12px;
  }

  .maintenance-modal-buttons-wrapper {
    padding-top: 24px;
    border-top: 1px solid #e7effb;
  }
}

.maintenance-tabs {
  width: 100%;
  margin-bottom: 16px;
  background: #f5f7f9;


  & .MuiButtonBase-root {
    font-family: Open Sans,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: $lightGunmetal;
    flex: 1;
  }

  & .Mui-selected {
    color: $main !important;
    font-weight: 700;
  }

  & .MuiTabs-indicator {
    background-color: $main;
  }
}

.right-modal-wrapper {
  display: flex;
  gap: 16px;

  > div:first-child {
    flex: 1;
  }

  .right-modal {
    transition: all 0.5s;
    box-shadow: 0 44px #fff, 0 -44px #fff, 44px 0 #fff, 44px -44px #fff, 44px 44px #fff;

    h4 {
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 0;
      text-align: left;
    }

    .mt-16 {
      width: fit-content;
      cursor: pointer;
      margin-top: 16px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0;
      text-align: center;
      color: #0e9594;
      margin-left: auto;
      padding: 8px 24px;
    }
  }
}

.maintenance-price-row {
  color: #506c84;
}

@media (max-width: 769px) {
  .add-service-button {
    margin-top: 16px;
  }
}

.maintenance-file-upload-label {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border: 4px dashed #b3dee1;
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 24px;
  height: 180px;

  @media only screen and (max-width: $md) {
    height: 180px;
  }

  &.hovered-label {
    border: 4px dashed #0e9594
  }

  .loading {
    width: 100%;
  }

  .maintenance-file-upload-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    color: #324557;


    .maintenance-file-upload-content-img {
      width: 54px;
      height: 54px;

      @media only screen and (max-width: $md) {
        width: 42px;
        height: 42px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .maintenance-file-upload-content-browse-file {
      margin: 12px 0;

      > span {
        color: #0e9594;
      }
    }
  }


  .maintenance-file-upload-input {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    opacity: 0;
    z-index: 34;
    cursor: pointer;
  }

  .maintenance-right-modal-files {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {

      color: #506C84;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px
    }

    .maintenance-file-upload-content-img {
      width: 54px;
      height: 54px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .maintenance-file-upload-content-browse-file {
      color: $tableHeadText;
      font-family: Open Sans, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      margin: 12px 0;

      span {
        color: #049494;
        font-family: Open Sans, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
      }
    }
  }
}

.maintenance-right-modal-main-files {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #E7EFFB;

  .maintenance-right-modal-main-file-image {
    width: 32px;
    height: 32px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}


