@import 'src/assets/styles/globalStyles';

.info-and-image {
  display: flex;
  align-items: center;

  p {
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
  }

  img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    @media (min-width: 1920px) {
      margin-right: 8px;
    }
  }
}

.hyper-link {
  background: transparent;
  padding: 0;
  border: none;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

// Full Table Component Styles

.full-table-styles {
  width: 100%;

  @media only screen and (max-width: 767px) {
    display: none;
  }

  & .table-wrapper {
    background: none;
    width: 100%;
    border-radius: 8px;
    margin-top: 16px;
    position: relative;

    & .MuiTableContainer-root {
      box-shadow: none;
      background: none;
      ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-scrollbar: {
        display: none;
      }
    }
  }

  .table-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 80vh;

    @media (min-width: 1919px) {
      height: 80vh;
    }
  }

  .table {
    &.MuiTable-root {
      border-collapse: separate;
      border-spacing: 0 8px;
    }
  }

  .table-head {
    border-radius: 8px;
    height: 51px;

    & > tr {
      border-radius: 8px;

      & > th:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      & > th:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    & .MuiTableCell-stickyHeader {
      background: $lightMain;
    }

    & .MuiTableCell-sizeSmall {
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      color: $gunmetal;
      padding: 8px 16px 8px 16px;
      border: none;
      @media (min-width: 1920px) {
        padding: 12px 16px 12px 16px;
      }
    }
  }

  .table-row {
    cursor: pointer;
    border-radius: 8px;
    background-color: $white;
    position: relative;

    & td {
      background-color: inherit;

      .margin-right-32 {
        margin-right: 32px;

        @media only screen and (max-width: $lg) {
          margin-right: 16px;
        }
      }
    }

    & > td:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    & > td:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:hover {
      background-color: #ebf2fd80;

      & > td {
        background-color: inherit;
      }
    }

    height: 44px;

    & .makeStyles-membersTableWrapper-26 .MuiTableContainer-root {
      background: $white;
    }

    & .MuiTableCell-sizeSmall {
      font-size: 14px;
      line-height: 22px;
      border: none;
      padding: 8px 16px 8px 16px;
      @media (min-width: 1920px) {
        padding: 12px 16px 12px 16px;
      }
    }

    & .MuiTableCell-sizeSmall:last-child {
      padding-top: 12px;
      font-size: 14px;
      line-height: 30px;
    }

    & .MuiSwitch-track {
      border-radius: 12px;
      background: $lightgray;
    }

    & .MuiSwitch-colorPrimary.Mui-checked {
      color: $white;
    }

    & .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
      background: $lightgray;
      border-radius: 12px;
      opacity: 1;
    }

    &.active {
      background: gray;
      cursor: default;
    }
  }
}

// Load Table Component Style.

.load-table-styles {
  width: 100%;

  & .table-wrapper {
    background: none;
    width: 100%;
    border-radius: 4px;
    margin-top: 16px;
    position: relative;

    & .MuiTableContainer-root {
      box-shadow: none;
      background: none;
      scrollbar-width: none;
      -webkit-scrollbar: {
        display: none;
      }
    }
  }

  .table-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 80vh;

    @media (min-width: 1919px) {
      height: 80vh;
    }
  }

  .table {
    &.MuiTable-root {
      border-collapse: separate;
      border-spacing: 0 4px;
    }
  }

  .table-head {
    border-radius: 8px;
    height: 51px;

    & > tr {
      border-radius: 8px;

      & > th:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      & > th:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    & .MuiTableCell-stickyHeader {
      background: $lightMain;
    }

    & .MuiTableCell-sizeSmall {
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      color: $gunmetal;

      padding: 8px 16px 8px 16px;
      border: none;
      @media (min-width: 1920px) {
        padding: 12px 16px 12px 16px;
      }
    }
  }

  .table-row {
    cursor: pointer;
    border-radius: 8px;
    background-color: white;
    position: relative;

    & td {
      background-color: inherit;
    }

    & > td:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    & > td:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:hover {
      background-color: #ebf2fd80;

      & > td {
        background-color: inherit;
      }
    }

    height: 44px;

    & .makeStyles-membersTableWrapper-26 .MuiTableContainer-root {
      background: $white;
    }

    & .MuiTableCell-sizeSmall {
      font-size: 14px;
      line-height: 22px;
      border: none;
      padding: 8px 16px 8px 16px;
      @media (min-width: 1920px) {
        padding: 12px 16px 12px 16px;
      }
    }

    & .MuiTableCell-sizeSmall:last-child {
      padding-top: 12px;
      font-size: 14px;
      line-height: 30px;
    }

    & .MuiSwitch-track {
      border-radius: 12px;
      background: $lightgray;
    }

    & .MuiSwitch-colorPrimary.Mui-checked {
      color: $white;
    }

    & .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
      background: $lightgray;
      border-radius: 12px;
      opacity: 1;
    }

    &.active {
      background: gray;
      cursor: default;
    }
  }

  .collapse-table {
    & .MuiTableCell-sizeSmall {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      padding: 8px 16px 8px 16px;
      border: none;
    }

    .collapse-box {
      background: $greyWhite;
      border-radius: 0px;
      padding: 12px 16px;
      display: grid;
      grid-template-columns: auto auto auto auto;
      grid-gap: 20px;

      .title {
        color: $gunmetal;
      }

      .number {
        color: $lightGunmetal;
      }
    }
  }
}

// Pagination Component Styles

.pagination-wrapper {
  width: 100%;
  margin: 4px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $lightMain;
  height: 52px;
  border-radius: 8px;
  padding: 10px 22px;

  & p {
    font-size: 14px;
    font-weight: 600;
    color: $tableHeadText;
  }

  & .MuiPaginationItem-root.Mui-selected {
    background: $main;
    color: white;
  }

  & .MuiPaginationItem-textPrimary.Mui-selected {
    background: $lightMain;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
  }

  & .MuiPaginationItem-page {
    background: none;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
  }

  & .MuiPaginationItem-outlined {
    background: $white;
    border: 1px solid $greyWhite;
    border-radius: 4px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    color: $darkElectric;
  }

  & .MuiPaginationItem-page.Mui-selected:hover {
    background: $main;
    color: $white;
  }
}

// Table No Info Styles

.no-info-wrapper {
  position: absolute;

  & .MuiTableCell-sizeSmall {
    padding: 6px;
    border: none;
    font-size: 18px;
    font-weight: bold;
  }

  & .MuiTableCell-root {
    border: none;
    font-size: 18px;
    font-weight: bold;
  }

  .no-info-text {
    border: none;
    font-size: 16px;
    line-height: 24px;
    color: $tableHeadText;
    font-weight: bold;
  }
}

// Popper Styles

.popper-wrapper {
  position: absolute;
  bottom: 5px;

  .btn {
    border: none;
    background: transparent;
    padding: 0;
    width: 10px;
  }
}

.popper-items-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & button {
    align-items: flex-start;
    padding: 9px 20px;
    border: none;
    background: transparent;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  & div {
    width: 100%;
  }

  & div:hover {
    background: $greyWhite;
  }
}

.pending-btn {
  background: $lightOrange;
  border-radius: 4px;
  color: $orange;
  outline: none;
  border: none;
  padding: 2px 8px;
  width: 70px;
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.resend-button {
  margin-left: 6px;
  background: transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  height: 24px;
  color: $main;
  text-decoration: underline;
  border: none;
  outline: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  @media (min-width: 768px) {
    margin-left: 18px;
  }
}

.active-status {
  padding: 2px 8px;
  width: 55px;
  height: 24px;
  background: #e1f2f3;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0e9594;
}

.inactive-btn {
  width: 67px;
  height: 24px;
  background: #e7effb;
  border-radius: 4px;
  color: $gunmetal500;
  outline: none;
  border: none;
  padding: 2px 8px;
}

.active-btn {
  background: $lightMain;
  border-radius: 4px;
  color: $main;
  outline: none;
  border: none;
  padding: 2px 8px;
  width: 55px;
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.active-text {
  background: $lightMain;
  border-radius: 4px;
  padding: 2px 8px;
  color: $main;
}

.inactive-text {
  background: #e7effb;
  border-radius: 4px;
  padding: 2px 8px;
  color: $gunmetal800;
}

.pending-text {
  background: #e7effb;
  border-radius: 4px;
  padding: 2px 8px;
  color: $gunmetal500;
}

.load-status {
  background: #e7effb;
  border-radius: 4px;
  padding: 4px 16px;
  color: $gunmetal500;
}

.transit-text {
  background: $lightOrange;
  border-radius: 4px;
  padding: 2px 8px;
  color: $blue;
}

.maintenance-text {
  background: $lightOrange;
  border-radius: 4px;
  padding: 2px 8px;
  color: $orange;
}

.hold-text {
  background: $lightOrange;
  border-radius: 4px;
  padding: 2px 8px;
  color: $blue;
}

.terminated-text {
  background: #e7effb;
  border-radius: 4px;
  padding: 2px 8px;
  color: #324557;
}

.recruitment-text {
  background: #ffe0b2;
  border-radius: 4px;
  padding: 2px 8px;
  color: #e65200;
}

.canceled-text {
  background: #d03325;
  border-radius: 4px;
  padding: 2px 8px;
  color: white;
}

.hold-text {
  background: $lightOrange;
  border-radius: 4px;
  padding: 2px 8px;
  color: $orange;
}

.not-use-text {
  background: rgba(68, 137, 242, 0.15);
  border-radius: 4px;
  padding: 2px 8px;
  color: $blue;
}

// Driver App Invite Color.

.invite-active {
  color: $main;
  font-size: 14px;
  font-weight: 400;
}

.invite-inactive {
  color: #5d7a95;
  font-size: 14px;
  font-weight: 400;
}

.invite-pending {
  color: #ff9a00;
  font-size: 14px;
  font-weight: 400;
}

.invite-hold {
  color: #ff9a00;
  font-size: 14px;
  font-weight: 400;
}

.invite-recruitmend {
  color: #e65200;
  font-size: 14px;
  font-weight: 400;
}

.invite-terminated {
  color: #324557;
  font-size: 14px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .active-text,
  .hold-text,
  .inactive-text,
  .pending-text,
  .transit-text,
  .maintenance-text,
  .hold-text,
  .terminated-text,
  .recruitment-text {
    padding: 8px 22px;
  }

  .pagination-wrapper {
    justify-content: center;

    .pagination-description {
      display: none;
    }
  }
}

.open-button {
  display: flex;
  align-items: center;

  button {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none;
    width: 20px;
    min-width: 20px;
    padding: 0;
    height: 18px;
    margin-left: 10px;
  }
}

.status-popper-wrapper {
  padding: 8px 24px !important;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(138, 138, 138, 0.16);
  border-radius: 4px;

  .MuiFormLabel-root {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #324557;
    margin-bottom: 10px;
  }

  & .MuiFormControlLabel-root {
    min-width: 100%;
    cursor: pointer;
    margin-right: 0 !important;
  }

  .check-label {
    margin: 2px 0;
    width: 100%;
    cursor: pointer;
  }
}

.action-box {
  display: flex;

  .action-danger {
    background: $red;
    border-radius: 4px;
    padding: 8px 36px;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .action-gunmetal {
    background: #c8d8e6;
    border-radius: 4px;
    padding: 8px 36px;
    color: $gunmetal800;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
}

.sortable-table-row-item {
  display: flex;
  align-items: center;

  .sortable-table-row-text {
    color: $gunmetal;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    white-space: nowrap;
  }

  > img {
    margin-left: 10px;
    cursor: pointer;
  }
}

.sortable-table-body-row-text {
  color: $gunmetal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

}

//ACCORDION TABLE

.accordion-table-wrapper {
  width: 100%;

  .accordion-table-header {
    background: $lightMain;
    border-radius: 8px;
    height: 50px;
  }

  .accordion-table-row {
    display: flex;
    align-items: center;
  }

  .accordion-table-call {
    display: flex;
    align-items: center;
    padding: 15px 16px 15px 16px;


    .accordion-table-component {

    }

    .accordion-table-text {
      color: $gunmetal;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      white-space: nowrap;
    }

    .accordion-table-img {
      margin-left: 10px;
    }
  }


  .MuiPaper-root {
    width: 100%;
    background: transparent;
    margin: 5px 0;
    border-radius: 8px;

    &.Mui-expanded {
      margin: 5px 0;
    }

    .MuiAccordionSummary-root {
      padding: 0;
      background: $white;
      height: 40px;

      .MuiAccordionSummary-expandIconWrapper {
        padding: 15px 16px 15px 16px;
      }

      .MuiAccordionSummary-content {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 0;

        .accordion-table-call {
          font-size: 14px;
          line-height: 22px;
          border: none;
          padding: 15px 16px 15px 16px;
        }
      }
    }

    .MuiCollapse-root {
      margin-top: 5px;

      .MuiCollapse-wrapper {
        .MuiCollapse-wrapperInner {
          .MuiAccordionDetails-root {
            padding: 0;

            .timesheet-details-accordion {
              min-height: 365px;
              height: 365px;
              max-width: 100%;
              margin: 0;

              &.timesheet-right-modal-process {
                height: 260px;
              }

              .timesheet-details-header-accordion {
                padding: 8px 24px;

                .timesheet-right-modal-details {
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 28px;
                  text-transform: capitalize;
                }
              }

              .timesheet-details-items-wrapper {
                display: flex;
                justify-content: space-between;
                width: 100%;
                max-width: 735px;

                .timesheet-right-modal-items-name, .timesheet-right-modal-items-param {
                  color: #758EA6;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px
                }
              }

              .timesheet-details-notes-wrapper {
                display: flex;
                align-items: center;

                .timesheet-right-modal-note-text {
                  margin-top: 0;
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}