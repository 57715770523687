@import "../../assets/styles/globalStyles";

.timesheet-drive-card-wrapper {
  width: 100%;
  background: $white;
  border-radius: 4px;

  .timesheet-drive-card-header {
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E7EFFB;
    padding: 16px 16px 16px 7px;

    &.timesheet-drive-card-header-padding {
      padding-left: 16px;
    }

    .price-and-text {
      display: flex;
      align-items: center;

      .price-and-text-checkbox {
        margin-right: 12px;
      }

      .price, .text {
        color: $gunmetal800;
        font-family: Open Sans, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
      }

      .text {
        font-weight: 600;
        margin-left: 7px;
      }
    }

    .edit-btn {
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }
  }


  .timesheet-drive-card-main-content {
    padding-top: 16px;
    border-bottom: 1px solid #E7EFFB;

    .less-detail {
      padding: 0 16px;
    }

    .more-detail {
      padding: 0 16px;
    }

    @keyframes show-overflow {
      from {
        overflow: hidden;
      }
    }

    .show-more-detail {
      max-height: 0;
      overflow: hidden;
      transition: max-height .5s linear;

      &.hide-more-detail {
        max-height: 500px;
        overflow: visible;
        animation: show-overflow .6s;
        transition: max-height .5s linear;
      }
    }


    .timesheet-drive-card-items-block {

      .timesheet-drive-card-item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
      }
    }

    .timesheet-drive-card-item-name, .timesheet-drive-card-item-params {
      color: $gunmetal;
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    .timesheet-drive-card-item-params {
      font-weight: 400;
      margin-left: 5px;
    }

    .timesheet-drive-card-notes-block {
      display: flex;
      align-items: start;
      padding: 12px 16px 0 16px;
      margin-bottom: 10px;
      border-top: 1px solid #E7EFFB;

    }

    .timesheet-card-send-btn {
      background: $orange;
      padding: 8px 36px;
      width: 311px;
      margin: 0 auto 20px;
      transition: all .3s;
      height: 40px;

      &:hover {
        transition: all .3s;
        background: $main;
      }
    }
  }
}

.timesheet-card-more-less {
  padding: 10px 16px;
}