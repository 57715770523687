@import "../../assets/styles/globalStyles";

.main-calendar-wrapper {
  width: 100%;
  position: relative;

  .custom-datepicker-footer {
    display: flex;
    justify-content: end;
    margin-top: 19px;
  }
}

.main-calendar-wrapper-and-buttons {
  position: absolute;
  border-radius: 8px;
  background: $white;
  box-shadow: 0 4px 16px 0 #E2DFDF;
  padding: 15px;
  border: none;
  right: 0;
  z-index: 9999999;
  transform: translate(0, 10px);

  &.position-static {
    position: static;
    transform: translate(0, 0);
  }

  .close-button {
    cursor: pointer;
  }
}

.main-calendar-button-wrapper {
}


.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.custom-input-for-calendar-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: $white;
  position: relative;

  .custom-input-for-calendar-text {
    .month, .year {
      color: $gunmetal;
      white-space: nowrap;
    }

    .month {
      margin-right: 5px;
    }
  }
}

.react-datepicker-popper {

}

.react-datepicker {
  width: 100%;
  display: flex;
  border: none;
  z-index: 19999;

  .react-datepicker__navigation--previous, .react-datepicker__navigation--next {
    top: 11px;
  }

  .react-datepicker__month-container {
    width: 100%;
    padding: 16px;

    .react-datepicker__header {
      width: fit-content;
      background: $white;
      padding: 0;
      border: none;

      .react-datepicker__current-month {
        color: $gunmetal800;
        font-size: 16px;
        font-weight: 600;
      }

      .react-datepicker__day-names {
        margin-top: 12px;
        margin-bottom: 4px;
        display: flex;
        justify-content: space-between;

        .react-datepicker__day-name {
          display: flex;
          justify-content: center;
          align-items: center;
          color: $gunmetal800;
          font-weight: 700;
          line-height: 20px;
          width: 35px;
          height: 35px;
          margin: 0.166rem;
        }
      }
    }

    .react-datepicker__month {
      margin: 0;

      .react-datepicker__week {
        display: flex;
        justify-content: space-between;


        .react-datepicker__day--in-selecting-range {
          //background: $greyWhite;
          //color: $tableHeadText;
        }

        .react-datepicker__day, .react-datepicker__day--outside-month, .react-datepicker__day--weekend {
          text-align: center;
          font-weight: 400;
          line-height: 20px;
          width: 35px;
          height: 35px;
        }

        .react-datepicker__day {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .react-datepicker__day--selected {
          &:hover {
            background: $darkGreen;
          }
        }
      }
    }
  }
}


.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #0e9594;
  color: $white;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  display: none;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  display: none;
}

.react-datepicker-popper[data-placement^='bottom'] {
  padding-top: 15px;
}

.react-datepicker__time-container {
  width: 100px;
}

.react-datepicker__header {
  background-color: white;
}

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list
li.react-datepicker__time-list-item {
  padding: 10px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #0e9594;
  color: $white;
}

  .react-datepicker__day--outside-month {
    background: $white;

    &:hover {
      background: white !important;
    }
  }

.react-datepicker__day--keyboard-selected[aria-selected = 'false'] {
  background: $white;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background: $darkGreen;
  color: $white;
}

.react-datepicker__day--in-selecting-range:not(
.react-datepicker__day--in-range,
 .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
   .react-datepicker__year-text--in-range
   ), .react-datepicker__month-text--in-selecting-range:not(
.react-datepicker__day--in-range,
 .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(
.react-datepicker__day--in-range, .react-datepicker__month-text--in-range,
 .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range
 ), .react-datepicker__year-text--in-selecting-range:not(
 .react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
   .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range) {
  background: $darkGreen;
  color: $white;

  &:hover {
    background: $darkGreen;
    color: $white;
  }
}

.react-datepicker__day--keyboard-selected:hover {
  background: $darkGreen;
  color: $white;
}


.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list
li.react-datepicker__time-list-item--selected {
  background-color: #0e9594;
  color: $white;
}
